import '../js-new/mbed-nav-vertical-menu';
import svg4everybody from 'svg4everybody';
import {initDismissableBanners} from '../js/banner-dismiss';
import {initNavNotification} from '../js/nav-notification';

window.mbed = window.mbed || {};
window.mbed.initDismissableBanners = initDismissableBanners;
window.mbed.initNavNotification = initNavNotification;

svg4everybody();
