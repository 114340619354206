import $ from "jquery";
import { getCookie } from "./common.js";

const navNotificationElementClass = ".mbed-nav__cta-notification";
const navDropdownContainerClass = ".mbed-nav__cta-dropdown-container";

function showNavNotification() {
  $(navNotificationElementClass).show();
}

function hideNavNotification() {
  $(navNotificationElementClass).hide();
}

function setNavNotificationSeen() {
  document.cookie = "nav_notification_seen = yes; path=/";
}

function isNavNotificationSeen() {
  return Boolean(getCookie("nav_notification_seen"));
}

function initNavNotification() {
  if (!isNavNotificationSeen()) {
    showNavNotification();

    $(navDropdownContainerClass).hover(function () {
      setNavNotificationSeen();
      hideNavNotification();
    });
  }
}

export {
  navNotificationElementClass,
  initNavNotification,
  setNavNotificationSeen,
  isNavNotificationSeen,
  hideNavNotification,
  showNavNotification,
};
