import $ from 'jquery';
import {getCookie} from './common.js';

function initDismissableBanners($dismissables) {
    $dismissables.each(function(index, dismissable) {
        const $dismissable = $(dismissable);
        $dismissable.find('.dismiss-button').click(function() {
            dismissBanner($dismissable);
        });
    });
}

function dismissBanner($bannerRow) {
    const dismissed_banner_id = $bannerRow.attr('data-dismissable-id');
    $bannerRow.remove();

    const disabled_banners_array = getDisabledBannersFromCookie();
    disabled_banners_array.push(dismissed_banner_id);
    setDisabledBannersCookie(disabled_banners_array);
}

function getDisabledBannersFromCookie() {
    const cookie_value = getCookie("disabled_banners");
    if (cookie_value) {
        return JSON.parse(cookie_value)
    }
    return []
}

function setDisabledBannersCookie(disabled_banners_array) {
    const disabled_banners_new = JSON.stringify(disabled_banners_array);
    document.cookie = "disabled_banners = " + disabled_banners_new + "; path=/";
}

export {
    initDismissableBanners,
    dismissBanner,
    setDisabledBannersCookie,
    getDisabledBannersFromCookie
};
